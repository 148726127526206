import routes from "vue-auto-routing";
import { RouteRecordRaw, createRouter, createWebHashHistory } from "vue-router";

import { Permission, useAuthStore } from "../store/auth";
import { useGeneralStore } from "../store/general";

routes.forEach((route) => {
  route.path = route.path.toLowerCase();
  if (route.children) {
    route.children.forEach((childRoute: RouteRecordRaw) => {
      childRoute.path = childRoute.path.toLowerCase();
    });
  }
});

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.addRoute({
  path: "/",
  redirect: () => (useAuthStore().isAuthenticated ? "devices" : "login"),
});

router.addRoute({
  path: "/:catchAll(.*)",
  meta: { removeNavigation: true },
  redirect: () => "error",
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const generalStore = useGeneralStore();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (generalStore.error != "" && !(to.fullPath === "/error")) {
      generalStore.error = "";
    }
    if (authStore.isAuthenticated) {
      if (authStore.permission && authStore.permission < (to.meta.minPermission as Permission)) {
        next("/error");
        return;
      }

      if (!generalStore.goingBack && from.meta.requiresAuth && !(Object.keys(to.params).length > 0)) {
        generalStore.lastPath.push(from.fullPath);
      }
      generalStore.goingBack = false;
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
