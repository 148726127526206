<template>
  <div class="modalMask">
    <div class="modalContainer">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalWindow",
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss" src="./ModalWindow.scss"></style>
