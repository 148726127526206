import { defineStore } from "pinia";

interface State {
  lastPath: string[];
  goingBack: boolean;
  error: string;
}

export const useGeneralStore = defineStore("general", {
  state: (): State => {
    return {
      lastPath: [],
      goingBack: false,
      error: "",
    };
  },
  getters: {},
  actions: {},
  persist: {
    storage: sessionStorage,
  },
});
