import axios from "axios";

import router from "../router";
import { useAuthStore } from "../store/auth";
import { useGeneralStore } from "../store/general";

const httpCommon = axios.create({});

httpCommon.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

if ("https://ss-payment.sspayment.sirsteward.com".match(/^http/)) {
  httpCommon.defaults.baseURL = "https://ss-payment.sspayment.sirsteward.com";
} else {
  httpCommon.defaults.baseURL = process.env.VUE_APP_SERVER_URL
    ? process.env.VUE_APP_SERVER_URL
    : "https://127.0.0.1:8001";
}
httpCommon.defaults.withCredentials = false;

/*
 * The interceptor here ensures that we check for the token in local storage every time an httpCommon request is made
 */
httpCommon.interceptors.response.use(
  (response) => {
    // intercept the global error
    //console.log(store.getters.StateToken)
    const store = useAuthStore();
    httpCommon.defaults.headers["X-ACCESS-TOKEN"] = store.StateToken;
    // const token = store.getters.StateToken
    // if (token) {
    //   response.headers['X-ACCESS-TOKEN'] = token
    // }
    return response;
  },
  (error) => {
    console.log(error);
    const authStore = useAuthStore();
    const generalStore = useGeneralStore();
    const originalRequest = error.config;
    if (!error.response) {
      generalStore.error = "503";
      router.push("/error");
      return;
    } else if (error.response.status === 401 && !originalRequest._retry) {
      // if the error is 401 and hasnt already been retried
      originalRequest._retry = true; // now it can be retried
      originalRequest.headers["X-ACCESS-TOKEN"] = authStore.StateToken; // new header new token
      httpCommon.defaults.headers["X-ACCESS-TOKEN"] = authStore.StateToken;
      return httpCommon(originalRequest); // retry the request that errored out
    } else if (error.response.status === 401) {
      authStore.logout;
      router.push("/login");
    } else if (error.response.status === 404 && !originalRequest._retry) {
      originalRequest._retry = true;
      generalStore.error = "404";
      router.push("/error");
      return;
    }
    // Do something with response error
    //return Promise.reject(error);
    return;
  }
);

export default httpCommon;
