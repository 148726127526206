const messages = {
  en: {
    ...require("./en.json"),
  },
  fr: {
    ...require("./fr.json"),
  },
};

export default messages;
