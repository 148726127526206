import JsonEditorVue from "json-editor-vue";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import App from "./App.vue";
import "./assets/styles/global.scss";
import router from "./router";
import messages from "./translate";

export const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  messages,
});

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedState);

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(JsonEditorVue);

app.mount("#app");
