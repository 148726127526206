<template>
  <div class="horizontal">
    <NavMenu v-if="navigationVisible" />
    <router-view class="pageBackground" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

import NavMenu from "./components/navMenu/NavMenu.vue";

export default defineComponent({
  name: "App",
  components: { NavMenu },

  setup() {
    const route = useRoute();
    const navigationVisible = computed(() => !route.meta.removeNavigation);
    return { navigationVisible };
  },
});
</script>

<style scoped lang="scss" src="./App.scss"></style>
