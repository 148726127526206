<template>
  <ModalWindow>
    <div class="popupContainer">
      <svg v-if="icon != undefined" :class="[popupIcon, PopupColors[color]]" preserveAspectRatio="xMaxYMax meet"></svg>
      <div :class="noCharLimit ? 'noCharLimitContainer' : 'textContainer'">
        <p :class="[regularText ? 'mainText' : 'pinMainText']">{{ $t(text) }}</p>
        <p :class="[regularText ? 'subText' : 'pinSubText']" v-if="subText">{{ subText }}</p>
      </div>
      <div v-if="buttons === PopupButtons.confirmCancel" class="buttonContainer">
        <button class="buttonSecondary" @click="$emit('cancel')">{{ $t("cancel") }}</button>
        <button :class="['buttonPrimary', PopupColors[color]]" @click="$emit('confirm')">{{ $t("confirm") }}</button>
      </div>
      <div v-else class="buttonContainer">
        <button :class="['buttonPrimary', PopupColors[color]]" @click="$emit('close')">{{ $t("close") }}</button>
      </div>
    </div>
  </ModalWindow>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from "vue";

import ModalWindow from "../ModalWindow.vue";

/* eslint-disable no-unused-vars */
export enum PopupColors {
  grey = 0,
  green = 1,
  yellow = 2,
  red = 3,
}

export enum PopupButtons {
  ok = 0,
  confirmCancel = 1,
}

export enum PopupIcons {
  Info = 0,
  Warning = 1,
  Error = 2,
}

export enum PopupText {
  regular = 0,
  pin = 1,
  noCharLimit = 2,
}
/* eslint-enable */

export default defineComponent({
  name: "Popup",
  components: {
    ModalWindow,
  },
  props: {
    text: { required: true, type: String },
    subText: { required: false, type: String },
    color: { required: true, type: Number as PropType<PopupColors> },
    buttons: { required: false, type: Number as PropType<PopupButtons> },
    icon: { required: false, type: Number as PropType<PopupIcons> },
    textType: { required: false, type: Number as PropType<PopupText> },
  },
  emits: ["confirm", "cancel", "close"],
  setup(props) {
    const popupIcon = computed(() => (props.icon != undefined ? "popupIcon" + PopupIcons[props.icon] : ""));
    const regularText = computed(
      () =>
        props.textType === undefined || props.textType === PopupText.noCharLimit || props.textType === PopupText.regular
    );
    const noCharLimit = computed(() => props.textType === PopupText.noCharLimit);
    return { popupIcon, PopupButtons, PopupColors, PopupIcons, regularText, noCharLimit };
  },
});
</script>

<style scoped lang="scss" src="./Popup.scss"></style>
